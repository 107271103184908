import { DynamicObject } from '../../utils/commonInterfaces';

export enum SingleObjectActionTypes {
    GET_SINGLE_OBJECT_DATA_REQUEST = '@@singleObject/GET_SINGLE_OBJECT_DATA_REQUEST',
    GET_SINGLE_OBJECT_DATA_SUCCESS = '@@singleObject/GET_SINGLE_OBJECT_DATA_SUCCESS',
    GET_SINGLE_OBJECT_DATA_ERROR = '@@singleObject/GET_SINGLE_OBJECT_DATA_ERROR',
    UPDATE_SINGLE_OBJECT_DATA_FILTERS = '@@singleObject/UPDATE_SINGLE_OBJECT_DATA_FILTERS',
    SET_SINGLE_OBJECT_DATA_SELECTED_DATA_REQUEST = '@@singleObject/SET_SINGLE_OBJECT_DATA_SELECTED_DATA_REQUEST',
    SET_SINGLE_OBJECT_DATA_SELECTED_DATA_SUCCESS = '@@singleObject/SET_SINGLE_OBJECT_DATA_SELECTED_DATA_SUCCESS',

    SET_SINGLE_OBJECT_MENU_FOLDED_STATUS = '@@singleObject/SET_SINGLE_OBJECT_MENU_FOLDED_STATUS',

    SET_SINGLE_OBJECT_LAST_MODIFIED_DATE = '@@singleObject/SET_SINGLE_OBJECT_LAST_MODIFIED_DATE',
    CLEAR_SINGLE_OBJECT_STATE = '@@singleObject/CLEAR_SINGLE_OBJECT_STATE',
}

export interface SingleObjectState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: SingleObject[];
    readonly originalData: DynamicObject;
    readonly regionList: string[];
    readonly filters: DynamicObject;
    readonly activeData: {
        loading: boolean;
        record: DynamicObject;
        selectedId: string | null;
    };
    readonly menuFolded: boolean;
    readonly lastModifiedDate: string | null;
}

export interface SingleObject {
    key?: string;
    ARN: string;
    ARNAccessingThis: string[];
    ARNCanAccess: string[];
    Area: string | null;
    Name: string | null;
    Purpose: string | null;
    Region: string | null;
    ServiceGroup: string | null;
    Type: string;
}
