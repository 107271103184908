import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { ApplicationState } from '..';
import { maxAPIRefetchCount, refetchAPIDelay } from '../../config/config';
import {
    checkShouldRequestRefetch,
    getEnvironmentIODMConnectUrl,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { clearTreeLayoutStateAction } from '../treeLayout/actions';
import { clearSingleObjectStateAction } from './../singleObject/actions';
import {
    getRegionKeyConfigAction,
    updateRegionKeyConfigAction,
} from './actions';
import { AuthActionTypes } from './types';

/**
 * Function for getting the currentUser
 */
export const getCurrentUser = (state: ApplicationState) =>
    state.auth.currentUser;

let refetchCount = 0;

/**
 * Function called for getting the region key config from the json file.
 */
function* handleGetRegionKeyConfig({ payload: environment }: any) {
    try {
        const iodmConnectUrl = getEnvironmentIODMConnectUrl(environment);
        const jsonResponse: DynamicObject = yield call(
            fetch,
            `${iodmConnectUrl}/multi-region-config.json`
        );
        const regionKeyConfig: DynamicObject[] = yield jsonResponse.json();
        refetchCount = 0;
        yield put(updateRegionKeyConfigAction(regionKeyConfig));
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getRegionKeyConfigAction(environment));
        } else {
            console.log('Error trying to get region key config', err);
        }
    }
}

/**
 * Function that sets the singleObject activeData and treeLayout activeData to initial value
 * @param param0
 */
function* handleUpdateSelectedEnvironment() {
    yield put(clearSingleObjectStateAction());
    yield put(clearTreeLayoutStateAction());
}

function* watchGetRegionKeyConfig() {
    yield takeLatest(
        AuthActionTypes.GET_REGION_KEY_CONFIG,
        handleGetRegionKeyConfig
    );
}

function* watchUpdateSelectedEnvironment() {
    yield takeLatest(
        AuthActionTypes.UPDATE_SELECTED_ENVIRONMENT,
        handleUpdateSelectedEnvironment
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* authSaga() {
    yield all([
        fork(watchGetRegionKeyConfig),
        fork(watchUpdateSelectedEnvironment),
    ]);
}

export default authSaga;
