/**
 * Component responsible for handling the layout of the entire app.
 */

import { Layout, Spin } from 'antd';
import { debounce } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AWSInfraManagementPage from '../../pages/awsInfra/AWSInfraManagementPage';
import GenerateRoutes from '../../routes/GenerateRoutes';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAuthHandler } from '../common/AuthHandler';
import PageHeader from './PageHeader';

const { Content } = Layout;

interface IProps {
    location: {
        pathname: string;
    };
    logoutUser: () => void;
    openNotificationsMenu: (count?: number) => void;
}
const PageLayout: React.FC<IProps> = (props: IProps) => {
    const [windowHeight, setWindowHeight] = useState<number>(
        window.innerHeight
    );

    const isMounted = useRef<boolean>(false);
    const dispatch = useDispatch();

    /**
     * PageSidebar routes / menu items.
     */
    const menuItems: DynamicObject[] = [
        // {
        //     name: 'Account Management',
        //     icon: ['fas', 'street-view'],
        //     route: '/account-management',
        //     component: AccountManagementPage,
        // },
        // {
        //     name: 'Onboarding',
        //     icon: ['fas', 'plus-square'],
        //     route: '/onboarding',
        //     component: OnboardingManagementPage,
        // },
        {
            name: 'Main Page',
            icon: ['fas', 'plus-square'],
            route: '/',
            component: AWSInfraManagementPage,
        },
    ];

    /**
     * Function that is called upon window resize.
     */
    const checkWindowSize = () => {
        setWindowHeight(window.innerHeight);
    };

    /**
     * Callback function that will be called whenever a window resize is triggered.
     * Applies debounce to keep a succeeding function from being called when resize is trigger in
     * a short span of time.
     */
    const resizeWindowHandler = useCallback(debounce(checkWindowSize, 400), []);

    /**
     * Function that adds a listener for window resize and binds it to a function.
     */
    const resizeWindowInitializer = () => {
        window.addEventListener('resize', resizeWindowHandler);
    };
    useLayoutEffect(resizeWindowInitializer, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, [dispatch]);

    return (
        <Spin
            spinning={false}
            tip="Loading company data"
            // size="large"
            style={{
                height: '80%',
            }}
        >
            <Layout
                className="h-100"
                style={{
                    maxHeight: windowHeight < 605 ? 605 : windowHeight,
                }}
            >
                <PageHeader
                    logoutUser={props.logoutUser}
                    openNotificationsMenu={props.openNotificationsMenu}
                    pageLayoutLoading={false}
                />
                <Layout>
                    {/* Sidebar Here */}
                    {/* <PageSidebar menuItems={menuItems} /> */}
                    <Layout className="page-layout-container">
                        <Content className="page-layout-content">
                            {/* Routes Section */}
                            <GenerateRoutes routeList={menuItems} />
                            {/* end Routes Section */}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </Spin>
    );
};

export default withRouter(withAuthHandler(PageLayout));
