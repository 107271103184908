import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { AuthActionTypes } from './types';

export const loginUserAction = (data: DynamicObject) =>
    action(AuthActionTypes.LOGIN_USER, data);
export const logoutUserAction = () => action(AuthActionTypes.LOGOUT_USER);
export const resetReduxStateAction = () =>
    action(AuthActionTypes.RESET_REDUX_STATE);

export const getRegionKeyConfigAction = (environment: string) =>
    action(AuthActionTypes.GET_REGION_KEY_CONFIG, environment);

export const updateRegionKeyConfigAction = (config: DynamicObject[]) =>
    action(AuthActionTypes.UPDATE_REGION_KEY_CONFIG, config);

export const updateSelectedEnvironmentAction = (environment: string) =>
    action(AuthActionTypes.UPDATE_SELECTED_ENVIRONMENT, environment);
