/**
 * AWS Infra Single object actions
 */
import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { SingleObject, SingleObjectActionTypes } from './types';

// Single object data
export const getSingleObjectDataRequestAction = (environment: string) =>
    action(SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_REQUEST, environment);
export const getSingleObjectDataSuccessAction = (payload: {
    data: SingleObject[];
    originalData: DynamicObject;
    regionList: string[];
}) => action(SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_SUCCESS, payload);
export const getSingleObjectDataErrorAction = (payload: string[]) =>
    action(SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_ERROR, payload);

export const updateSingleObjectFiltersAction = (filters: DynamicObject) =>
    action(SingleObjectActionTypes.UPDATE_SINGLE_OBJECT_DATA_FILTERS, filters);

// Single Record
export const setSelectedSingleObjectDataRequestAction = (
    singleObjectData: SingleObject | null,
    callback?: () => void
) =>
    action(
        SingleObjectActionTypes.SET_SINGLE_OBJECT_DATA_SELECTED_DATA_REQUEST,
        {
            singleObjectData,
            callback,
        }
    );
export const setSelectedSingleObjectDataSuccessAction = (
    singleObjectData: SingleObject | null
) =>
    action(
        SingleObjectActionTypes.SET_SINGLE_OBJECT_DATA_SELECTED_DATA_SUCCESS,
        singleObjectData
    );

export const setSingleObjectMenuFoldedStatusAction = (folded?: boolean) =>
    action(
        SingleObjectActionTypes.SET_SINGLE_OBJECT_MENU_FOLDED_STATUS,
        folded
    );

export const setSingleObjectLastModifiedDateAction = (
    lastModifiedDate: string
) =>
    action(
        SingleObjectActionTypes.SET_SINGLE_OBJECT_LAST_MODIFIED_DATE,
        lastModifiedDate
    );

export const clearSingleObjectStateAction = () =>
    action(SingleObjectActionTypes.CLEAR_SINGLE_OBJECT_STATE);
