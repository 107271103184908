import update from 'immutability-helper';
import { get } from 'lodash';
import { Reducer } from 'redux';
import { TreeLayoutActionTypes, TreeLayoutState } from './types';

// Type-safe initialState!
export const initialState: TreeLayoutState = {
    loading: false,
    errorMessages: [],
    data: [],
    originalData: {},
    regionList: [],
    filters: {
        searchText: '',
        searchInclusions: {
            ARN: {
                checked: true,
                caseSensitive: false,
            },
            Name: {
                checked: true,
                caseSensitive: false,
            },
            Purpose: {
                checked: true,
                caseSensitive: false,
            },
            Area: {
                checked: true,
                caseSensitive: false,
            },
            Type: {
                checked: true,
                caseSensitive: false,
            },
        },
        excludedRegions: [],
    },
    activeData: {
        loading: false,
        record: {},
        selectedId: null,
        highlightedArn: null,
    },
    menuFolded: false,
    lastModifiedDate: null,
    selectedTargetObjectArn: null,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<TreeLayoutState> = (state = initialState, action) => {
    switch (action.type) {
        case TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_REQUEST: {
            return { ...state, loading: true };
        }
        case TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_SUCCESS: {
            const newDataState = update(state.data, {
                $set: action.payload.data,
            });

            const newOriginalDataState = update(state.originalData, {
                $set: action.payload.originalData,
            });

            const newRegionListState = update(state.regionList, {
                $set: action.payload.regionList,
            });

            return {
                ...state,
                loading: false,
                data: newDataState,
                originalData: newOriginalDataState,
                regionList: newRegionListState,
                errorMessages: initialState.errorMessages,
            };
        }
        case TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                originalData: initialState.originalData,
                errorMessages: action.payload,
            };
        }
        case TreeLayoutActionTypes.UPDATE_TREE_LAYOUT_DATA_FILTERS: {
            return { ...state, filters: action.payload };
        }

        //Single Record
        case TreeLayoutActionTypes.SET_TREE_LAYOUT_DATA_SELECTED_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                        record: initialState.activeData.record,
                    },
                },
            });

            return newState;
        }

        case TreeLayoutActionTypes.SET_TREE_LAYOUT_DATA_SELECTED_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: get(
                            action.payload,
                            'key',
                            initialState.activeData.selectedId
                        ),
                        record: action.payload,
                    },
                },
            });

            return newState;
        }

        case TreeLayoutActionTypes.SET_TREE_LAYOUT_MENU_FOLDED_STATUS: {
            const newMenuFoldedState = update(state.menuFolded, {
                $set: action.payload,
            });

            return {
                ...state,
                menuFolded: newMenuFoldedState,
            };
        }

        case TreeLayoutActionTypes.SET_HIGHLIGHTED_ARN: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        highlightedArn: action.payload,
                    },
                },
            });

            return newState;
        }

        case TreeLayoutActionTypes.SET_TREE_LAYOUT_LAST_MODIFIED_DATE: {
            return {
                ...state,
                lastModifiedDate: action.payload,
            };
        }

        case TreeLayoutActionTypes.SET_TREE_LAYOUT_SELECTED_TARGET_OBJECT_ARN: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        highlightedArn: action.payload,
                    },
                },
            });

            return {
                ...newState,
                selectedTargetObjectArn: action.payload,
            };
        }

        case TreeLayoutActionTypes.CLEAR_TREE_LAYOUT_STATE: {
            return {
                ...initialState,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as treeLayoutReducer };
