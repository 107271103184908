import { Auth, Storage } from 'aws-amplify';
import { forEach, includes, orderBy } from 'lodash';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import { Environments } from '../../AmplifyConfig';
import {
    maxAPIRefetchCount,
    refetchAPIDelay,
    USERPOOL_IDENTITIES,
} from '../../config/config';
import {
    awsS3Config,
    infrastructureFilesPath,
    infrastructureFilesPathDev,
} from '../../constants/common';
import {
    checkShouldRequestRefetch,
    getCurrentBuildEnvironment,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { SingleObject } from '../singleObject/types';
import {
    getTreeLayoutDataErrorAction,
    getTreeLayoutDataRequestAction,
    getTreeLayoutDataSuccessAction,
    setSelectedTreeLayoutDataSuccessAction,
    setTreeLayoutLastModifiedDateAction,
} from './actions';
import { TreeLayout, TreeLayoutActionTypes } from './types';

let refetchCount = 0;

export const hasArnInChild = (arnActive: string, treeNode: TreeLayout) => {
    return includes(JSON.stringify(treeNode), `"ARN":"${arnActive}"`);
};

/**
 * Function called for getting the tree layout from data file.
 */
function* handleGetTreeLayoutData({ payload: environment }: any) {
    try {
        const buildEnv = getCurrentBuildEnvironment();
        Auth.configure({
            identityPoolId: USERPOOL_IDENTITIES[buildEnv],
        });
        Auth.currentCredentials();

        const usedFilePath =
            buildEnv === Environments.DEV
                ? infrastructureFilesPathDev
                : infrastructureFilesPath;

        const presignedUrl: string = yield Storage.get(
            `${usedFilePath}/architectureDataFull_tree_${environment}.txt`,
            awsS3Config
        );

        const jsonResponse: DynamicObject = yield call(fetch, presignedUrl);
        const treeLayoutData: DynamicObject = yield jsonResponse.json();
        const treeLayoutSelectedTargetObjectArn: string = yield select(
            (app: ApplicationState) => app.treeLayout.selectedTargetObjectArn
        );

        const treeEntriesArray: SingleObject[] = [];
        const regionList: string[] = [];
        let originalTreeData: DynamicObject = {};
        forEach(treeLayoutData, (d: TreeLayout) => {
            const hasArn = treeLayoutSelectedTargetObjectArn
                ? hasArnInChild(treeLayoutSelectedTargetObjectArn, d)
                : true;

            if (!hasArn) return;
            treeEntriesArray.push(d.thisNode);
            const regionVal = d.thisNode.Region;
            if (regionVal && !includes(regionList, regionVal))
                regionList.push(regionVal);

            originalTreeData[d.thisNode.ARN] = d;
        });
        const responsePayload = {
            data: orderBy(treeEntriesArray, ['Name']),
            originalData: originalTreeData,
            regionList,
        };

        yield put(getTreeLayoutDataSuccessAction(responsePayload));
        const lastModifiedDate = jsonResponse.headers.get('Last-Modified');
        yield put(setTreeLayoutLastModifiedDateAction(lastModifiedDate));
        refetchCount = 0;
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getTreeLayoutDataRequestAction(environment));
        } else {
            const errorMessage =
                'Error fetching tree entry points data. Please try again later.';
            yield put(getTreeLayoutDataErrorAction([errorMessage]));
            yield put(setTreeLayoutLastModifiedDateAction(''));
        }
    }
}

/**
 * Function that sets the selected tree layout data in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedTreeLayoutDataRequest({ payload }: any) {
    const { treeLayoutData, callback } = payload;
    yield put(setSelectedTreeLayoutDataSuccessAction(treeLayoutData));
    if (callback) callback();
}

function* watchGetTreeLayoutData() {
    yield takeLatest(
        TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_REQUEST,
        handleGetTreeLayoutData
    );
}

function* watchSetSelectedTreeLayoutDataRequest() {
    yield takeLatest(
        TreeLayoutActionTypes.SET_TREE_LAYOUT_DATA_SELECTED_DATA_REQUEST,
        handleSetSelectedTreeLayoutDataRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* treeLayoutSaga() {
    yield all([
        fork(watchGetTreeLayoutData),
        fork(watchSetSelectedTreeLayoutDataRequest),
    ]);
}

export default treeLayoutSaga;
