import { Col, Radio, Row, Spin } from 'antd';
import { map } from 'lodash';
import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Environments } from '../../AmplifyConfig';
import { ApplicationState } from '../../store';
import { updateSelectedEnvironmentAction } from '../../store/auth/actions';
import { updateSelectedViewingOptionAction } from '../../store/common/actions';
import { DynamicObject } from '../../utils/commonInterfaces';

const SingleObjectView = lazy(
    () => import('../../components/awsInfra/SingleObjectView')
);
const TreeLayoutView = lazy(
    () => import('../../components/awsInfra/TreeLayoutView')
);

interface IProps {}
export const viewingOptions: DynamicObject = {
    SingleObject: {
        label: 'Targeted Object',
        value: 'SingleObject',
        component: <SingleObjectView />,
    },
    TreeLayout: {
        label: 'Call Tree',
        value: 'TreeLayout',
        component: <TreeLayoutView />,
    },
};
const AWSInfraManagementPage: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const singleObjectLastModifiedDate = useSelector(
        (app: ApplicationState) => app.singleObject.lastModifiedDate
    );
    const treeLayoutLastModifiedDate = useSelector(
        (app: ApplicationState) => app.treeLayout.lastModifiedDate
    );
    const selectedEnvironment: string = useSelector(
        (app: ApplicationState) => app.auth.environment
    );
    const selectedViewingOption = useSelector(
        (app: ApplicationState) => app.app.selectedViewingOption
    );

    /**
     * Function called when a button in the radio button group is clicked - viewing options.
     */
    const onViewingOptionChange = (e: any) => {
        dispatch(updateSelectedViewingOptionAction(e.target.value));
    };

    /**
     * Function for populating the radio buttons for viewing options.
     */
    const populateViewingOptions = () => {
        const options = map(viewingOptions, (opt: DynamicObject) => (
            <Radio.Button key={opt.value} value={opt.value}>
                {opt.label}
            </Radio.Button>
        ));

        return (
            <Radio.Group
                value={selectedViewingOption}
                onChange={onViewingOptionChange}
            >
                {options}
            </Radio.Group>
        );
    };

    /**
     * Function called when selected environment is updated.
     */
    const updateSelectedEnvironment = (e: any) => {
        dispatch(updateSelectedEnvironmentAction(e.target.value));
    };

    /**
     * Function called for populating the environments.
     */
    const populateEnvironments = () => {
        const options = map(Environments, (env: string, index: string) => (
            <Radio.Button key={index} value={env}>
                {env}
            </Radio.Button>
        ));

        return (
            <Radio.Group
                value={selectedEnvironment}
                onChange={updateSelectedEnvironment}
            >
                {options}
            </Radio.Group>
        );
    };

    /**
     * Common function for getting the last modified date of the file (dependent on the viewing option selected).
     */
    const getActiveFileLastModifiedDate = () => {
        let activeFileLastModifiedDate = '--';
        if (
            selectedViewingOption === viewingOptions.SingleObject.value &&
            singleObjectLastModifiedDate
        ) {
            activeFileLastModifiedDate = singleObjectLastModifiedDate;
        }
        if (
            selectedViewingOption === viewingOptions.TreeLayout.value &&
            treeLayoutLastModifiedDate
        ) {
            activeFileLastModifiedDate = treeLayoutLastModifiedDate;
        }
        return activeFileLastModifiedDate;
    };

    const renderLoader = () => <Spin />;

    return (
        <div
            className="main-container"
            style={{
                height: window.innerHeight - 84,
            }}
        >
            <Row
                className="h-100 fd-column"
                type="flex"
                justify="center"
                align="middle"
            >
                <Col className="pb-8" span={24}>
                    <Row type="flex" align="middle">
                        <Col className="ta-left" span={8}>
                            File last modified:{' '}
                            <b>{getActiveFileLastModifiedDate()}</b>
                        </Col>
                        <Col className="ta-center" span={8}>
                            {populateViewingOptions()}
                        </Col>
                        <Col className="ta-right" span={8}>
                            {populateEnvironments()}
                        </Col>
                    </Row>
                </Col>
                <Col className="fx-1 w-100 dd-container pa-10">
                    <div
                        className="h-100"
                        style={
                            selectedViewingOption ===
                            viewingOptions.SingleObject.value
                                ? {}
                                : { display: 'none' }
                        }
                    >
                        <Suspense fallback={renderLoader}>
                            <SingleObjectView />
                        </Suspense>
                    </div>
                    <div
                        className="h-100"
                        style={
                            selectedViewingOption ===
                            viewingOptions.TreeLayout.value
                                ? {}
                                : { display: 'none' }
                        }
                    >
                        <Suspense fallback={renderLoader}>
                            <TreeLayoutView />
                        </Suspense>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AWSInfraManagementPage;
