import { Auth, Storage } from 'aws-amplify';
import { forEach, includes, orderBy } from 'lodash';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { Environments } from '../../AmplifyConfig';
import {
    maxAPIRefetchCount,
    refetchAPIDelay,
    USERPOOL_IDENTITIES,
} from '../../config/config';
import {
    awsS3Config,
    infrastructureFilesPath,
    infrastructureFilesPathDev,
} from '../../constants/common';
import {
    checkShouldRequestRefetch,
    getCurrentBuildEnvironment,
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import {
    getSingleObjectDataErrorAction,
    getSingleObjectDataRequestAction,
    getSingleObjectDataSuccessAction,
    setSelectedSingleObjectDataSuccessAction,
    setSingleObjectLastModifiedDateAction,
} from './actions';
import { SingleObject, SingleObjectActionTypes } from './types';

let refetchCount = 0;

/**
 * Function called for getting the single object from data file.
 */
function* handleGetSingleObjectData({ payload: environment }: any) {
    try {
        const buildEnv = getCurrentBuildEnvironment();

        Auth.configure({
            identityPoolId: USERPOOL_IDENTITIES[buildEnv],
        });
        Auth.currentCredentials();

        const usedFilePath =
            buildEnv === Environments.DEV
                ? infrastructureFilesPathDev
                : infrastructureFilesPath;

        const presignedUrl: string = yield Storage.get(
            `${usedFilePath}/architectureDataFull_single_${environment}.txt`,
            awsS3Config
        );

        const jsonResponse: DynamicObject = yield call(fetch, presignedUrl);
        const singleObjectData: DynamicObject = yield jsonResponse.json();

        const singleObjectArray: SingleObject[] = [];
        const regionList: string[] = [];
        forEach(singleObjectData, (d: SingleObject) => {
            singleObjectArray.push(d);
            if (d.Region && !includes(regionList, d.Region))
                regionList.push(d.Region);
        });
        const responsePayload = {
            data: orderBy(singleObjectArray, ['Name']),
            originalData: singleObjectData,
            regionList,
        };

        yield put(getSingleObjectDataSuccessAction(responsePayload));
        const lastModifiedDate = jsonResponse.headers.get('Last-Modified');
        yield put(setSingleObjectLastModifiedDateAction(lastModifiedDate));
        refetchCount = 0;
    } catch (err) {
        console.log('err: ', err);
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getSingleObjectDataRequestAction(environment));
        } else {
            const errorMessage =
                'Error fetching single object data. Please try again later.';
            yield put(getSingleObjectDataErrorAction([errorMessage]));
            yield put(setSingleObjectLastModifiedDateAction(''));
        }
    }
}

/**
 * Function that sets the selected single object data in the redux state for reference.
 * @param param0
 */
function* handleSetSelectedSingleObjectDataRequest({ payload }: any) {
    const { singleObjectData, callback } = payload;
    yield put(setSelectedSingleObjectDataSuccessAction(singleObjectData));
    if (callback) callback();
}

function* watchGetSingleObjectData() {
    yield takeLatest(
        SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_REQUEST,
        handleGetSingleObjectData
    );
}

function* watchSetSelectedSingleObjectDataRequest() {
    yield takeLatest(
        SingleObjectActionTypes.SET_SINGLE_OBJECT_DATA_SELECTED_DATA_REQUEST,
        handleSetSelectedSingleObjectDataRequest
    );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* singleObjectSaga() {
    yield all([
        fork(watchGetSingleObjectData),
        fork(watchSetSelectedSingleObjectDataRequest),
    ]);
}

export default singleObjectSaga;
