import update from 'immutability-helper';
import { get } from 'lodash';
import { Reducer } from 'redux';
import { SingleObjectActionTypes, SingleObjectState } from './types';

// Type-safe initialState!
export const initialState: SingleObjectState = {
    loading: false,
    errorMessages: [],
    data: [],
    originalData: {},
    regionList: [],
    filters: {
        searchText: '',
        searchInclusions: {
            ARN: {
                checked: true,
                caseSensitive: false,
            },
            Name: {
                checked: true,
                caseSensitive: false,
            },
            Purpose: {
                checked: true,
                caseSensitive: false,
            },
            Area: {
                checked: true,
                caseSensitive: false,
            },
            Type: {
                checked: true,
                caseSensitive: false,
            },
        },
        excludedRegions: [],
    },
    activeData: {
        loading: false,
        record: {},
        selectedId: null,
    },
    menuFolded: false,
    lastModifiedDate: null,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<SingleObjectState> = (state = initialState, action) => {
    switch (action.type) {
        case SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_REQUEST: {
            return { ...state, loading: true };
        }
        case SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_SUCCESS: {
            const newDataState = update(state.data, {
                $set: action.payload.data,
            });

            const newOriginalDataState = update(state.originalData, {
                $set: action.payload.originalData,
            });

            const newRegionListState = update(state.regionList, {
                $set: action.payload.regionList,
            });

            return {
                ...state,
                loading: false,
                data: newDataState,
                originalData: newOriginalDataState,
                regionList: newRegionListState,
                errorMessages: initialState.errorMessages,
            };
        }
        case SingleObjectActionTypes.GET_SINGLE_OBJECT_DATA_ERROR: {
            return {
                ...state,
                loading: false,
                data: initialState.data,
                originalData: initialState.originalData,
                errorMessages: action.payload,
            };
        }
        case SingleObjectActionTypes.UPDATE_SINGLE_OBJECT_DATA_FILTERS: {
            return { ...state, filters: action.payload };
        }

        //Single Record
        case SingleObjectActionTypes.SET_SINGLE_OBJECT_DATA_SELECTED_DATA_REQUEST: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: initialState.activeData.selectedId,
                        record: initialState.activeData.record,
                    },
                },
            });

            return newState;
        }

        case SingleObjectActionTypes.SET_SINGLE_OBJECT_DATA_SELECTED_DATA_SUCCESS: {
            const newState = update(state, {
                activeData: {
                    $merge: {
                        selectedId: get(
                            action.payload,
                            'key',
                            initialState.activeData.selectedId
                        ),
                        record: action.payload,
                    },
                },
            });

            return newState;
        }

        case SingleObjectActionTypes.SET_SINGLE_OBJECT_MENU_FOLDED_STATUS: {
            const newMenuFoldedState = update(state.menuFolded, {
                $set: action.payload,
            });

            return {
                ...state,
                menuFolded: newMenuFoldedState,
            };
        }

        case SingleObjectActionTypes.SET_SINGLE_OBJECT_LAST_MODIFIED_DATE: {
            return {
                ...state,
                lastModifiedDate: action.payload,
            };
        }

        case SingleObjectActionTypes.CLEAR_SINGLE_OBJECT_STATE: {
            return {
                ...initialState,
            };
        }

        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as singleObjectReducer };
