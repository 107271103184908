export const AWS_INFRA_PAGE = {
    pageSize: 20,
    rowHeight: 100,
};

export const PAGE_NAMES_FOR_VIEW = {
    AWS_INFRA_PAGE: 'AwsInfraPage',
};

export const DETAILS_TAB = {
    AUDIT: {
        pageSize: 20,
        rowHeight: 70,
    },
};

export const AWS_INFRA_SEARCH = {
    pagSize: 100,
    rowHeight: 100,
};
