/**
 * AWS Infra Tree layout actions
 */
import { action } from 'typesafe-actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { SingleObject } from '../singleObject/types';
import { TreeLayout, TreeLayoutActionTypes } from './types';

// Tree layout data
export const getTreeLayoutDataRequestAction = (environment: string) =>
    action(TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_REQUEST, environment);
export const getTreeLayoutDataSuccessAction = (payload: {
    data: SingleObject[];
    originalData: DynamicObject;
    regionList: string[];
}) => action(TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_SUCCESS, payload);
export const getTreeLayoutDataErrorAction = (payload: string[]) =>
    action(TreeLayoutActionTypes.GET_TREE_LAYOUT_DATA_ERROR, payload);

export const updateTreeLayoutFiltersAction = (filters: DynamicObject) =>
    action(TreeLayoutActionTypes.UPDATE_TREE_LAYOUT_DATA_FILTERS, filters);

// Single Record
export const setSelectedTreeLayoutDataRequestAction = (
    treeLayoutData: TreeLayout | null,
    callback?: () => void
) =>
    action(TreeLayoutActionTypes.SET_TREE_LAYOUT_DATA_SELECTED_DATA_REQUEST, {
        treeLayoutData,
        callback,
    });
export const setSelectedTreeLayoutDataSuccessAction = (
    treeLayoutData: TreeLayout | null
) =>
    action(
        TreeLayoutActionTypes.SET_TREE_LAYOUT_DATA_SELECTED_DATA_SUCCESS,
        treeLayoutData
    );

export const setTreeLayoutMenuFoldedStatusAction = (folded?: boolean) =>
    action(TreeLayoutActionTypes.SET_TREE_LAYOUT_MENU_FOLDED_STATUS, folded);

export const setHighlightedArnAction = (arn: string | null) =>
    action(TreeLayoutActionTypes.SET_HIGHLIGHTED_ARN, arn);

export const setTreeLayoutLastModifiedDateAction = (lastModifiedDate: string) =>
    action(
        TreeLayoutActionTypes.SET_TREE_LAYOUT_LAST_MODIFIED_DATE,
        lastModifiedDate
    );

export const setTreeLayoutSelectedTargetObjectArnAction = (
    arn: string | null
) =>
    action(
        TreeLayoutActionTypes.SET_TREE_LAYOUT_SELECTED_TARGET_OBJECT_ARN,
        arn
    );

export const clearTreeLayoutStateAction = () =>
    action(TreeLayoutActionTypes.CLEAR_TREE_LAYOUT_STATE);
