import { DynamicObject } from '../utils/commonInterfaces';

export const TIMEOUT_MILLISECOND = 1200000; // in milliseconds. Default value is 20 minutes 1200000
export const xlMin = 1200;
export const sitePrefix = 'infrastructure';

export const TOKEN_NAME = `iodm${sitePrefix}jwttoken`;
export const DEFAULT_REGION_NAME = 'Australia';
export const PASSWORD_RULE_MESSAGE =
    'Password must be at least 8 characters long and contain a combination of uppercase letters, lowercase letters, numbers and a special character (such as !, @, #, $, %, ^, etc).';
export const EMAIL_RULE_MESSAGE = 'Please enter a valid email address.';
export const USER_DOES_NOT_EXIST = 'The email address entered does not exist.';

export const API_NAME = 'IODM_API';
export const SYSTEM_ENDPOINT_DEV = 'dev.iodmconnectonline.com';
export const SYSTEM_ENDPOINT_TEST = 'test.iodmconnectonline.com';
export const SYSTEM_ENDPOINT_UAT = 'uat.iodmconnectonline.com';
export const SYSTEM_ENDPOINT_NONPROD = 'sandbox.iodmconnectonline.com';
export const SYSTEM_ENDPOINT_PROD = 'iodmconnectonline.com';

export const API_ENDPOINT_DEV = `https://api.${sitePrefix}.${SYSTEM_ENDPOINT_DEV}`;
export const API_ENDPOINT_TEST = `https://api.${sitePrefix}.${SYSTEM_ENDPOINT_TEST}`;
export const API_ENDPOINT_UAT = `https://api.${sitePrefix}.${SYSTEM_ENDPOINT_UAT}`;
export const API_ENDPOINT_NONPROD = `https://api.${sitePrefix}.${SYSTEM_ENDPOINT_NONPROD}`;
export const API_ENDPOINT_PROD = `https://api.${sitePrefix}.${SYSTEM_ENDPOINT_PROD}`;

// export const APPSYNC_ENDPOINT_DEV =
//     'https://6ufr7b2czva4vnlgr7oyl2k6fe.appsync-api.ap-southeast-2.amazonaws.com/graphql';
export const APPSYNC_ENDPOINT_DEV = `${API_ENDPOINT_DEV}/graphql`;
export const APPSYNC_ENDPOINT_TEST = `${API_ENDPOINT_TEST}/graphql`;
export const APPSYNC_ENDPOINT_UAT = `${API_ENDPOINT_UAT}/graphql`;
export const APPSYNC_ENDPOINT_NONPROD = `${API_ENDPOINT_NONPROD}/graphql`;
export const APPSYNC_ENDPOINT_PROD = `${API_ENDPOINT_PROD}/graphql`;

// export const USERPOOL_ID_DEV = 'ap-southeast-2_ZH2yHELxh';
export const USERPOOL_ID_DEV = 'ap-southeast-2_ETj5FLUYr';
export const USERPOOL_ID_TEST = 'ap-southeast-2_Evv7YWzzk';
export const USERPOOL_ID_UAT = 'ap-southeast-2_fC9cc149L';
export const USERPOOL_ID_NONPROD = 'ap-southeast-2_gd86ieCH9';
export const USERPOOL_ID_PROD = 'ap-southeast-2_evwjvWvUd';

// export const USERPOOL_WEBCLIENT_ID_DEV = '3skvn5ihvpelpqc3c7ef25piec';
export const USERPOOL_WEBCLIENT_ID_DEV = 'amlb07m1c3no7jgiqgv8aj1bm';
export const USERPOOL_WEBCLIENT_ID_TEST = '2aa82pem1rj4v3rg81j1ej084h';
export const USERPOOL_WEBCLIENT_ID_UAT = '24sj1v43mncb3j3b4dkmb8d6vu';
export const USERPOOL_WEBCLIENT_ID_NONPROD = '5jli8mhp8beoqrmk7jpo0gai54';
export const USERPOOL_WEBCLIENT_ID_PROD = '3ps7a363fkc06leigiapt6dsi3';

export const USERPOOL_IDENTITIES: DynamicObject = {
    dev: 'ap-southeast-2:7f653b31-7861-41c2-b7bc-33e25c012b69',
    test: 'ap-southeast-2:cbdee601-365f-4b4d-88a0-af1b7adb7ba0',
};

// Cookies expiry - in number of days
export const TOKEN_NAME_EXPIRY = 1;
export const INVITE_DETAILS_EXPIRY = 1;

export const AWS_REGION_SE_2 = 'ap-southeast-2';
export const AWS_APPSYNC_AUTH_TYPE_COGNITO = 'AMAZON_COGNITO_USER_POOLS';
export const AWS_APPSYNC_AUTH_TYPE_API_KEY = 'API_KEY';
export const AWS_APPSYNC_AUTH_TYPE_AWS_IAM = 'AWS_IAM';

export const initialPrimaryColor = '#eb2f96';
export const initialSecondaryColor = '#4D4D4F';

export const notificationPollTime = 30000; //in ms
export const notificationAfterActionDelayPollTime = 10000; //in ms

export const confirmModalOkText = 'Yes';
export const confirmModalCancelText = 'No';

export const ASSETS_LINK_DEV =
    'https://iodm-company-logo-dev.s3-ap-southeast-2.amazonaws.com';

export const ASSETS_LINK_TEST =
    'https://iodm-company-logo-test.s3-ap-southeast-2.amazonaws.com';

export const ASSETS_LINK_UAT =
    'https://iodm-company-logo-uat.s3-ap-southeast-2.amazonaws.com';

export const ASSETS_LINK_NONPROD =
    'https://iodm-company-logo-nonprod.s3-ap-southeast-2.amazonaws.com';

export const ASSETS_LINK_PROD =
    'https://iodm-company-logo-prod.s3-ap-southeast-2.amazonaws.com';

export const taskJobHistoryStatusPollTime = 5000; //in ms

export const pixelAllowanceScrollToFetchMore = 5;

export const COOKIE_LAST_ACTIVE_NAME = `iodm${sitePrefix}CookieLastActive`;
export const TIME_ACTIVE_COOKIE_SETTING = 400; //in ms

export const TIME_DELAY_LISTENER_FILTER_UPDATES = 100;

export const maxAPIRefetchCount = 2;
export const refetchAPIDelay = 3000;

export const DEFAULT_LOCALE = 'en-AU';
export const DEFAULT_CURRENCY_CODE = 'AUD';

// export const GOOGLE_ANALYTICS_TRACKING = {
//     dev: 'G-J3CTE1EH8H',
//     test: 'G-J8FGF88CPN',
//     nonprod: 'G-641Q0NFCEH',
//     uat: 'G-C8E96NMCXN',
//     prod: 'G-ZJ0Y7RTZBS',
// };

export const IODM_COMPANY_STORAGE_NAME = `iodm-${sitePrefix}-selected-company`;
export const AMPLIFY_AUTH_STATE_STORAGE_NAME =
    'amplify-authenticator-authState';
