import { DynamicObject } from '../../utils/commonInterfaces';
import { SingleObject } from '../singleObject/types';

export enum TreeLayoutActionTypes {
    GET_TREE_LAYOUT_DATA_REQUEST = '@@treeLayout/GET_TREE_LAYOUT_DATA_REQUEST',
    GET_TREE_LAYOUT_DATA_SUCCESS = '@@treeLayout/GET_TREE_LAYOUT_DATA_SUCCESS',
    GET_TREE_LAYOUT_DATA_ERROR = '@@treeLayout/GET_TREE_LAYOUT_DATA_ERROR',
    UPDATE_TREE_LAYOUT_DATA_FILTERS = '@@treeLayout/UPDATE_TREE_LAYOUT_DATA_FILTERS',
    SET_TREE_LAYOUT_DATA_SELECTED_DATA_REQUEST = '@@treeLayout/SET_TREE_LAYOUT_DATA_SELECTED_DATA_REQUEST',
    SET_TREE_LAYOUT_DATA_SELECTED_DATA_SUCCESS = '@@treeLayout/SET_TREE_LAYOUT_DATA_SELECTED_DATA_SUCCESS',
    SET_TREE_LAYOUT_MENU_FOLDED_STATUS = '@@treeLayout/SET_TREE_LAYOUT_MENU_FOLDED_STATUS',
    SET_HIGHLIGHTED_ARN = '@@treeLayout/SET_HIGHLIGHTED_ARN',
    SET_TREE_LAYOUT_LAST_MODIFIED_DATE = '@@treeLayout/SET_TREE_LAYOUT_LAST_MODIFIED_DATE',
    SET_TREE_LAYOUT_SELECTED_TARGET_OBJECT_ARN = '@@treeLayout/SET_TREE_LAYOUT_SELECTED_TARGET_OBJECT_ARN',
    CLEAR_TREE_LAYOUT_STATE = '@@treeLayout/CLEAR_TREE_LAYOUT_STATE',
}

export interface TreeLayoutState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly data: TreeLayout[];
    readonly originalData: DynamicObject;
    readonly regionList: string[];
    readonly filters: DynamicObject;
    readonly activeData: {
        loading: boolean;
        record: DynamicObject;
        selectedId: string | null;
        highlightedArn: string | null;
    };
    readonly menuFolded: boolean;
    readonly lastModifiedDate: string | null;
    readonly selectedTargetObjectArn: string | null;
}

export interface TreeLayout {
    key?: string;
    forward: boolean;
    items: TreeLayout[];
    thisNode: SingleObject;
}
